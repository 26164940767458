'use client';

import classNames from 'classnames/bind';
import React from 'react';

import { Button } from '@components/Button/Button';
import { Image } from '@components/Image/Image';
import { Container } from '@components/Layout/Container/Container';
import { Paragraph } from '@components/Paragraph/Paragraph';
import { Section } from '@components/Section/Section';
import { routes } from '@routes';

import styles from './CtaPanel.module.scss';
import ctaGlobeImage from './cta-globe.png';
import ctaLinesImage from './cta-lines.svg';

type IButton = {
  id?: string;
  title: string;
} & (
  | {
      href: string;
      onClick?: never;
    }
  | { onClick: () => void; href?: never }
);
export interface IProps {
  title?: string | JSX.Element;
  text?: string;
  buttons?: IButton[];
  background?: 'globe' | 'lines';
  className?: string;
  children?: React.ReactNode;
}

const cx = classNames.bind(styles);

export const CtaPanel: React.FC<IProps> = ({
  title = 'Deliver your content with CDN77',
  text,
  buttons = [
    { title: 'Request tailored offer', href: routes.requestdOffer, id: 'cta-contact' },
    { title: 'Start your free trial', href: routes.signup, id: 'cta-sign-up' },
  ],
  background = 'globe',
  className,
  children,
}) => (
  <Section overflow="hidden" padding={0} withContainer={false}>
    <Container className={className}>
      <Section borderRadius="rounded" className={styles.section} padding={90} isPulled>
        {background === 'globe' && (
          <figure className={styles.background}>
            <Image alt="" height={569} src={ctaGlobeImage} width={569} />
          </figure>
        )}
        {background === 'lines' && (
          <img
            alt=""
            className={styles.linesBackground}
            height={855}
            src={ctaLinesImage}
            width={1110}
          />
        )}
        <div className={styles.content}>
          <h2 className={cx('title', text ? 'mb-2' : 'mb-5')}>{title}</h2>
          {text && (
            <Paragraph align="center" className="mb-5" color="light" opacity={60} size="lg">
              {text}
            </Paragraph>
          )}
          {children}

          <div className={styles.buttonsWrapper}>
            {buttons.map(({ title, ...rest }, index) => (
              <Button
                key={typeof title === 'string' ? `${title}-${index}` : index}
                className={styles.button}
                theme="button"
                variant={index === 0 ? 'primary' : 'inverted'}
                {...rest}
              >
                {title}
              </Button>
            ))}
          </div>
        </div>
      </Section>
    </Container>
  </Section>
);
